.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

th {
  text-align: left;
  padding: 5px
}

td {
  text-align: left;
  padding-left: 5px
}

button {
  min-height: 25px;
  border-radius: 8px;
  border-width: 2px;
}

button:hover {
  border-color: lightgray;
  background-color: white;
}

.headingStyle {
  font-size: large;
  font-weight: bold;
}

.dataRow {
  display: flex;
  flex-direction: row;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  height: calc(100vh - 10px);
  width: calc(100vw - 10px);
  background-image: url('./images/platform2.jpg');
  background-position: top;
}

.firmSelect {
  display: flex;
  flex-direction: row;
}

.firmSelectComponent {
  border-radius: 10px;
  text-align: center;
  background-color: rgba(245, 245, 245, 0.95);
}

.firmSelectComponent:hover {
  border-color: lightgray;
  background-color: white;
}

.dataContainer {
  width: calc(100% - 1px);
  min-height: 30%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.dataBox {
  display: flex;
  backdrop-filter: blur(3px);
  flex-direction: column;
  min-height: 30%;
  max-height: 100%;
  width: calc(50% - 10px);
  border: 1px solid darkgrey;
  overflow-y: auto;
  border-radius: 10px;
  padding-left: 5px;
  margin: 5px;
  background-color: rgba(245, 245, 245, 0.95);

}

.userDetail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 2;
  border: 1px solid darkgrey;
  border-radius: 10px;
  margin: 5px;
  padding-left: 5px;
  background-color: rgba(245, 245, 245, 0.95);
}

.userDetailHead {
  display: flex;
  flex-direction: row;
}

.userDetailSection {
  margin: 10px;
  padding-top: 5px;
}

.userDetailTable {
  min-width: 25%;
}